<template>
  <ion-app class="app_theme">
    <ion-content>
      <menuComponent />
      <!-- <headerComponent /> -->
      <ion-router-outlet />
      <!-- <tabsPage /> -->
    </ion-content>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, IonContent } from "@ionic/vue";
// import tabsPage from "./views/TabsPage.vue";
// import headerComponent from "./components/Header.vue";
import menuComponent from "./components/MenuComponent.vue";
import { defineComponent } from "vue";
import { useStore, mapActions } from "vuex";
import { useBackButton, useIonRouter } from "@ionic/vue";
import { App } from "@capacitor/app";
import { SplashScreen } from "@capacitor/splash-screen";
// import { Network } from "@capacitor/network";

export default defineComponent({
  name: "App",
  components: {
    menuComponent,
    // headerComponent,
    // tabsPage,
    IonApp,
    IonRouterOutlet,
    IonContent,
  },
  data() {
    return {
      // networkStatus: false,
    };
  },
  setup() {
    const store = useStore();
    const ionRouter = useIonRouter();
    useBackButton(-1, () => {
      if (!ionRouter.canGoBack()) {
        App.exitApp();
      }
    });
    return {
      store,
    };
  },
  mounted() {
    SplashScreen.hide();
    // console.log(sessionStorage.getItem("user"));
  },
  methods: {
    ...mapActions(["userAuth"]),
  },
  async created() {
    // const status = await Network.getStatus();
    // console.log("Network status:", status.connected);
    // this.networkStatus = status.connected;
    // Network.addListener("networkStatusChange", (status) => {
    //   console.log("Network status changed:", status.connected);
    // });
    // Hide the splash screen when the app loads
    // SplashScreen.hide();
  },
});
</script>
<style>
/* .app_theme {
  --ion-background-color-rgb: rgb(249, 249, 249);
} */
</style>
