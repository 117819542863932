<template>
  <ion-menu content-id="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>SokaLive</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <router-link to="../login" class="link">
        <ion-menu-toggle>
          <ion-button class="button ion-text-center">
            <!-- <ion-item> -->
            <!-- <ion-avatar slot="start">
              <img
                alt="profile"
                src="https://ionicframework.com/docs/img/demos/avatar.svg"
                style="height: 90%; width: auto"
              />
            </ion-avatar> -->
            <ion-label v-if="user">{{ user.name }}</ion-label>
            <ion-label v-else> Login / Register </ion-label>
            <!-- </ion-item> -->
          </ion-button>
        </ion-menu-toggle>
      </router-link>
      <div lines="none" v-if="user && user.role_id == 1">
        <!-- <ion-item> -->
        <router-link to="../league_management" class="link">
          <ion-menu-toggle>
            <ion-button class="button ion-text-center">
              <ion-label>Leagues</ion-label>
            </ion-button>
          </ion-menu-toggle>
        </router-link>
        <!-- </ion-item> -->
        <!-- <ion-item>
          <ion-label>Matches</ion-label>
        </ion-item> -->
        <!-- <ion-item> -->
        <router-link to="../team_management" class="link">
          <ion-menu-toggle>
            <ion-button class="button ion-text-center">
              <ion-label>Teams</ion-label>
            </ion-button>
          </ion-menu-toggle>
        </router-link>
        <!-- </ion-item> -->
        <!-- <ion-item>
          <router-link to="../events" class="link">
            <ion-label>Events</ion-label>
          </router-link>
        </ion-item> -->
        <!-- {{ user }} -->
      </div>
      <div class="app-developer ion-text-center">
        <!-- <a
          href="https://donaldtz.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ion-button expand="block" class="ion-text-center"
            >App Developer</ion-button
          >
        </a> -->
        <span class="ion-text-center">Version 1.8.0</span>
      </div>
    </ion-content>
  </ion-menu>
</template>

<script>
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  // IonPage,
  IonMenu,
  IonContent,
  // IonList,
  // IonItem,
  IonLabel,
  // IonAvatar,
  IonButton,
  IonMenuToggle,
} from "@ionic/vue";
// import { useStore, mapGetters } from "vuex";
import storeData from "../store/index";
export default {
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    // IonPage,
    IonMenu,
    IonContent,
    // IonList,
    // IonItem,
    IonLabel,
    // IonAvatar,
    IonButton,
    IonMenuToggle,
  },
  data() {
    return {
      user: {},
    };
  },
  created() {
    this.user = storeData.state.user;
    // console.log(this.user);
  },
  setup() {
    // const store = useStore();
    // console.log(this.$store);
    // return {
    //   store,
    // };
  },
  computed: {
    // ...mapGetters(["user"]),
    // user() {
    //   return this.store.state.user;
    // },
  },
};
</script>

<style scoped>
.link {
  text-decoration: none;
}
.app-developer {
  bottom: 0;
  position: absolute;
  width: 85%;
}
.button {
  background: none;
  width: 85%;
}
</style>
