import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios'
import { createStore } from "vuex";
import { SplashScreen } from '@capacitor/splash-screen';
import { Network } from "@capacitor/network";

//Checking network availability
Network.addListener('networkStatusChange', (status) => {
  // console.log('Network status changed:', status.connected);
  if (status.connected) {
    // Reopen the application
    // window.location.href = "/";
    // router.push('/');
  }
});

const store = createStore({
  /* state, actions, mutations */
});
// import store from './store/index.js'
import token from './store/index.js'

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

axios.defaults.headers = {
  "Authorization":`Bearer ${token.state.TOKEN}`,
  "Accept":'application/json',
  'Content-Type': 'application/json; charset=utf-8'
}

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';
axios.defaults.baseURL = 'https://livescore.donaldtz.com/api/';

// backendUrl = 'http://127.0.0.1:8000/'

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(SplashScreen)
  .use(Network)

  // Change the website title to "My Custom Title"
document.title = 'SokaLive';

  // .provide('store', store)
  // app.config.globalProperties.$backendUrl = 'http://127.0.0.1:8000/'
  app.config.globalProperties.$backendUrl = 'https://livescore.donaldtz.com/'
  // .use(backendUrl)

  // axios.defaults.withCredentials = true;

  //splash screen 
  // Vue.config.productionTip = false;

// Register the SplashScreen plugin
// Vue.use(SplashScreen);
  
router.isReady().then(() => {
  app.mount('#app');
});