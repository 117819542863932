<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="matches" href="/tabs/matches">
          <ion-icon :icon="football" />
          <ion-label>Matches</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="explore" href="/tabs/explore">
          <ion-icon :icon="trophyOutline" />
          <ion-label>Leagues</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="news" href="/tabs/news">
          <ion-icon :icon="newspaper" />
          <ion-label>News</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonIcon,
  IonRouterOutlet,
} from "@ionic/vue";
import { trophyOutline, newspaper, football } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  components: {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    return {
      trophyOutline,
      newspaper,
      football,
    };
  },
});
</script>
