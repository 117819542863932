import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '../store/index';
// import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes = [
  // {
  //   path: '/',
  //   redirect: '/tabs/tab1'
  // },
  {
    path: '/',
    name:'matches',
    redirect: '/tabs/matches'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      // {
      //   path: '',
      //   redirect: '/tabs/tab1'
      // },
      {
        path: '',
        // name:'matches',
        redirect: '/tabs/matches'
      },
      {
        path:'matches',
        // name:'matches',
        component: () => import('@/views/Matches.vue')
      },
      // {
      //   path: 'tab1',
      //   component: () => import('@/views/Tab1Page.vue')
      // },
      {
        path: 'tab2',
        component: () => import('@/views/Tab2Page.vue')
      },
      {
        path: 'explore',
        component: () => import('@/views/ExplorePage.vue')
      },
      {
        path: 'news',
        component: () => import('@/views/NewsPage.vue')
      },
      // {
      //   path: 'news',
      //   component: () => import('@/views/News.vue')
      // }
    ]
  },
  {
    path: '/match/:id',
    component: ()=> import('@/views/Match.vue')
  },
  {
    path: '/teams/:id',
    component: ()=> import('@/views/TeamsPage.vue')
  },
  {
    path: '/team_players/:league_id/:team_id',
    component: ()=> import('@/views/PlayersPage.vue')
  },
  {
    path: '/league/:id',
    component: ()=> import('@/views/LeaguePage.vue')
  },
  {
    path: '/top_scorer/:id',
    component: ()=> import('@/views/TopScorer.vue')
  },
  {
    path: '/top_assists/:id',
    component: ()=> import('@/views/TopAssist.vue')
  },
  {
    path: '/most_yellow/:id',
    component: ()=> import('@/views/MostYellow.vue')
  },
  {
    path: '/most_red/:id',
    component: ()=> import('@/views/MostRed.vue')
  },
  {
    path: '/league_management',
    name:'league_management',
    component: ()=> import('@/views/admin/LeaguesManagement.vue')
  },
  {
    path: '/team_management',
    name:"team_management",
    component: ()=> import('@/views/admin/TeamManagement.vue')
  },
  {
    path: '/add_players/:id',
    name:"add_players",
    component: ()=> import('@/views/admin/AddPlayers.vue')
  },
  {
    path: '/league_groups/:id',
    name:'league_groups',
    component: ()=> import('@/views/admin/LeagueGroups.vue')
  },
  {
    path: '/group/:id/:league',
    name:'group',
    component: ()=> import('@/views/admin/GroupTeams.vue')
  },
  {
    path: '/news/:id',
    component: () => import('@/views/SingleNews.vue')
  },
  {
    path: '/login',
    name:'Login',
    component: () => import('@/views/auth/LoginPage.vue')
  },
  {
    path: '/signup',
    name:'Register',
    component: () => import('@/views/auth/SignUp.vue')
  },
  // {
  //   path: '/events',
  //   component: () => import('@/views/admin/EventsManagement.vue')
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next)=>{
  const protectedRoutes = ["league_management","league_groups","group","team_management","add_players"];
  if(!store.state.TOKEN && protectedRoutes.includes(to.name)){
    next({name:'Login'})
  }else if(store.state.TOKEN && (to.name == 'Login' || to.name == 'Register')){
    next({name:'matches'});
  }else{
    if (store.state.user && store.state.user.role_id != 1 && protectedRoutes.includes(to.name)) {
      next({name:'matches'});
    }else{
      next();
    }
  }
})

export default router
