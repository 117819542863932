import { createStore } from 'vuex'

export default createStore({
  state: {
    user:JSON.parse(sessionStorage.getItem('user')),
    TOKEN:sessionStorage.getItem('TOKEN'),
  },
  mutations: {
    // setUserData:(state,userData)=>{
    //     state.user.data = userData[0]
    //     state.user.token = userData[1]
    //     sessionStorage.setItem('data',JSON.stringify(userData[0]))
    //     sessionStorage.setItem('TOKEN',userData[1]);
    // },
    // logout:(state)=>{
    //     state.user = {
    //       id:'',
    //       name:'',
    //       email:''
    //     };
    //     state.TOKEN = '';
    //     sessionStorage.removeItem('TOKEN');
    //     sessionStorage.removeItem('user');
    // },
    setAuthUserData(state){
      state.user = sessionStorage.getItem('user');
      state.TOKEN = sessionStorage.getItem('TOKEN');
    }
  },
  actions: {
    // async login({commit},user_data){
    //     const response = await axios.post('login',user_data);
    //     if (response.data[0] == 401) {
    //         // alert(response.data[1])
    //         return response.data;
    //     }
    //     axios.defaults.headers = {
    //         "Authorization":`Bearer ${response.data[1]}`,
    //         "Accept":'application/json'
    //         }
    //     commit("setUserData",response.data)
    //     return response.data
    // },

    userAuth({commit}){
      commit('setAuthUserData')
    }

    // async register({commit},user_data){
    //     const response = await axios.post('register',user_data);
    //     axios.defaults.headers = {
    //         "Authorization":`Bearer ${response.data[1]}`,
    //         "Accept":'application/json'
    //         }
    //     commit("setUserData",response.data)
    // },
    // async logout({commit}){
    //     const response = await axios.post('logout');
    //     axios.defaults.headers = {};
    //     commit('logout')
    //     return response;
    // }
  },
  getters: {
    // your getter methods go here
    user(state){
      return state.user;
    }
  }
})
